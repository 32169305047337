.container .chat {
  max-width: 800px;
}

.chat-box {
  padding: 15px;
}

.message {
  display: flex;
  margin-bottom: 15px;
}

.message .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #007bff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  margin-right: 10px;
}

.message .message-content {
  background: #f1f3f4;
  padding: 10px 15px;
  border-radius: 10px;
  max-width: 70%;
}

.message.sent {
  flex-direction: row-reverse;
}

.message.sent .avatar {
  background: #28a745;
  margin-right: 0;
  margin-left: 10px;
}

.message.sent .message-content {
  background: #c5f3cf;
}

.text-muted {
  display: block;
  margin-top: 5px;
  font-size: 0.8rem;
  color: #6c757d;
}
.chat form {
  display: flex;
  width: 100%;
}
.chat .form-control {
  flex:1;
  margin-right: 10px;
  border-radius: 0.25rem !important;

}
.chat .btn {
  padding: .375rem 2rem;
}

