.login-form {
  width: 340px;
  margin: 6rem auto;
  font-size: 15px;
}
.login-form {
  margin-bottom: 15px;
  background: #e9e7de;
  padding: 30px;
  border-radius:20px;
}
.login-form h2 {
  margin: 0 0 15px;
}
.login-form .hint-text {
  color: #777;
  padding-bottom: 15px;
  text-align: center;
  font-size: 13px; 
}

.login-btn {        
  font-size: 15px;
  font-weight: bold;
}
.or-seperator {
  margin: 20px 0 10px;
  text-align: center;
  border-top: 1px solid #ccc;
}
.or-seperator i {
  padding: 0 10px;
  background: #e9e7de;
  position: relative;
  top: -11px;
  z-index: 1;
}
.social-btn .btn {
  margin: 10px 0;
  font-size: 15px;
  text-align: left; 
  line-height: 24px;       
}
.social-btn .btn i {
  float: left;
  margin: 4px 15px  0 5px;
  min-width: 15px;
}
.input-group-addon .fa{
  font-size: 18px;
}
.insta {
  background-color: #d12798;
  color: #fff;
}