body.product h4 {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 0.5rem;
}

@media screen and (max-width: 440px) {
  body.product #root {
    margin-top:55px;
    padding:0;
  }

  body.product .card-deck {
    margin-bottom: 0!important;
  }
  body.product .card.shadow-sm {
    margin-bottom: 0!important;
  }
}