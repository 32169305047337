#companies > div:first-child {    
  background: no-repeat url("./companies2.jpeg");
  background-size: cover;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-position: bottom;
  min-height: 600px;
  max-width: 100%;
  background-attachment: fixed;
  padding: 14rem 4.5rem;
}

#companies > div:first-child div {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  text-align: center;
}

#companies .container {
  margin-top: 2rem;
}

@media (max-width: 768px) {

  #companies div:first-child div {
    padding: 1rem;
  }
}