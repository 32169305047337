
#user {
  background: none;
  border: none;
  border-radius: 30px;
  padding: 0;
}

#user img {
  border-radius: 30px;
  height: 36px;
}

.navbar-expand-md .navbar-nav .dropdown-menu {
    position: fixed;
    padding: 20px;
    right: 20px;
    top: 60px;
    width: fit-content;
    left: auto;
}
.navbar-nav li {
  position: relative;
}
.badge {
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: red;
  color: white;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}