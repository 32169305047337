#support > div:first-child {    
  background: no-repeat url("./Support2.png");
  background-size: cover;
  max-width: 100%;
  padding: 14rem 4.5rem;
  background-size: contain;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-position: right;
}

#support > div:first-child h2 {
  text-align: left;
} 

#support .container {
  margin-top: 2rem;
}

@media (max-width: 768px) {
  #support > div:first-child {
    padding: 5rem 2.5rem;
    margin-top: 3.5rem;
  }
  #support > div:first-child div {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 12px;
    display: inline-block;
    padding: 1rem;
  }
}