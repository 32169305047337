#home .container:first-child {    
  background-image: url("./lost.jpeg");
  background-size: cover;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-position: bottom;
  min-height: 600px;
  max-width: 100%;
  background-attachment: fixed;
  padding: 14rem 4.5rem;
}

#home .container:first-child div {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 12px;
}

.upper {
  font-size: 0.5em;
  vertical-align: text-top;
}

.small-desc {
  font-size: 0.8rem;
  font-style: italic;
  text-align: center;
}
#home .card-body {
  display: grid;
}

@media (max-width: 768px) {

  #home .container:first-child div {
    padding: 1rem;
  }
}