#TopMenu {
  background-color: #393939;
  height: 3.75rem;
  position: absolute;
  width: 100%;
  top: 0;
}

#TopMenu a {
  color: #EEEEEE;
  padding: 5px;
}

.navbar {
  background-color: #fbf9ef;
  transition: 0.3s;
  font-weight: bold;
  padding: 1rem 1.5rem;
}

.navbar a {
  color: #1e2203;
}

.navbar a:hover {
  color: #727d6b;
}

#profile .btn {
  padding: .375rem 2rem;
}

.btn-danger:hover {
  background-color: #91585e;
  transition: 0.3ms;
}

.btn-danger a {
  color: #fbf9ef;
}

.btn-danger:hover a {
  color: #fbf9ef;
}

@media (max-width: 768px) {
  #profile {
    position: fixed;
    right: 12px;
    top: 8px;
  }
  .navbar h1 {
    position: fixed;
    left: 100px;
    top: 10px;
  }
  .navbar {
    padding: 0.5rem 1.5rem;
  }
}

