#root {
    margin-top: 4.5rem;
}
#root .mt {
  margin-top: 6rem;
}

.btn a {
    color: #fff;
  
  }
  
  .btn a:hover {
    text-decoration: none;
  }


@media (max-width: 768px) {
  #root > div > div:first-child{
    margin-top: 6rem;
  }

  h2 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
}

.prod-info {
  white-space: break-spaces;
}