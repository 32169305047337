.message-list-item {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  border: 1px solid rgba(0, 0, 0, .125);
  list-style-type: none;
  cursor: pointer;
}
.message-list-item:hover {
  background-color: #f3f2ec;
}

.message-list-item:first-child {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.message-list-item:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}