#order th, #order td {
  text-align: right;
}

#order table {
  width: calc(90% - 128px);
  margin-left: 1rem;
  margin-right:0;
  margin-bottom: 0;
}

#order table th, #order table td {
  vertical-align: middle!important;
}

#order table .btn {
  width: 75px;;
}
#order table td div {
  max-height: 1.5rem;
  overflow: hidden;
}

#order input[type="checkbox"] {
  position: absolute;
  margin-top: 2.6rem;
  margin-left: 2.2rem;
}

#order svg {
  margin-right: 0;
  margin-left: auto;
}

#order .clearfix .float-right {
  white-space: nowrap;
}

@media screen and (max-width: 992px) {
  #order table {
    width: calc(85% - 128px);
  }
}

@media screen and (max-width: 576px) {
  .container {
    width: 95%;
  }

  #order table {
    width: calc(95% - 128px);
  }

  #order input[type="checkbox"] {
    position: absolute;
    margin-top: 3.7rem;
    margin-left: 10.2rem;
  }
}

@media screen and (max-width: 468px) {
  #order table {
    width: 92%;
  }
  #order svg {
    display: none;
  }
}