form button:disabled {
  background-color: #ccc!important;
  border-color: #ddd!important;
}
.product-form .clearfix .float-right {
  white-space: nowrap;
}

#root .btn-warning:hover {
  background-color: #d1a318;
  color: #fbf9ef;
}

@media screen and (max-width: 440px) {
  .login-btn {
    margin-right: 0px!important;
  }
}