body {
  margin: 0;
  font-family: -apple-system, Cabin,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 2rem;
  margin: 0 1rem 0 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form-control, .btn {
  border-radius: 8px;
  min-height: 38px;
  color:#fbf9ef;
  font-weight: bold;
  transition: 0.3s;
}

form-control,  #root .btn-action, .btn-success {
  color: #fbf9ef;
  background-color: #35452b;
  border: unset;
}

form-control, #root .btn-action:hover, .btn-success:hover {
  background-color: #727d6b;
}

#root .alert {
  border-radius: 20px;
}
.card-header {
  background-color: unset;
}

.full-width {
  background-color: #768350;
  color: #fbf9ef;
  padding: 8rem 1.5rem;
}

h1 a:hover {
  text-decoration: none;
}

h2 {
  text-align: center;
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 150%;
}

.jumbotron {
  text-align: center;
  min-height: 400px;
}

.container .card {
  background-color: #e9e7de;
  border:none;
  border-radius:20px;
}

.card h4 {
  padding: .75rem 1.25rem;
  text-transform: uppercase;
}

.price {
  font-size: 4rem;
  font-weight: 700;
}

#root a {
  transition: 0.3s;
}

input[type=checkbox] {
  margin-right:0.5rem;
}

#cookies h2, #terms h2 {
  text-align: left;
  font-size: 1.5rem;
}

.card {
  word-wrap: break-all;
}

footer {
  background-color: #c7b090;
  padding: 8rem 0;
  margin-top: 3rem;
}

footer a {
  color: #fbf9ef;
}